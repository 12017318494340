import {
  ComparativeRapportInput,
  ComparativeRapportOutput,
  QueryTicketCompareRapportArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { reactive, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import moment from "moment";
import { formatDate, formatNumber } from "@/graphql/utils/utils";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";

type ComparativeRapportData = {
  ticketCompareRapport: ComparativeRapportOutput[];
  expenseCompareRapport: ComparativeRapportOutput[];
};

const COMPARATIVE_RAPPORT = gql`
  query ComparativeRapport($input: ComparativeRapportInput!) {
    ticketCompareRapport(input: $input) {
      createdAt
      number
      amount
      total
    }
    expenseCompareRapport(input: $input) {
      createdAt
      amount
    }
  }
`;

type ComparativeRapport = {
  number: number;
  createdAt: Date;
  income: number;
  expense: number;
  balance: number;
  total: number;
  credit: number;
};
const FILTER = {
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  income: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  credit: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  expense: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  balance: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const useComparativeRapport = () => {
  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d } = useI18n();

  const input = reactive<ComparativeRapportInput>({
    startAt: moment(date).add(-1, "days").toDate(),
    endAt: date,
    activityId: activeActivity.value.id,
    period: null,
  });
  const { loading, load, onResult } = useLazyQuery<
    ComparativeRapportData,
    QueryTicketCompareRapportArgs
  >(COMPARATIVE_RAPPORT);
  const comparisons = ref<ComparativeRapport[]>([]);
  onResult(({ data }) => {
    comparisons.value.length = 0;
    if (data) {
      const dates = new Set<string>(
        [...data.expenseCompareRapport, ...data.ticketCompareRapport].map(
          (res) => formatDate(res.createdAt)
        )
      );
      Array.from(dates).forEach((date) => {
        const exp = data.expenseCompareRapport.filter(
          (e) => formatDate(e.createdAt) === date
        );
        const inc = data.ticketCompareRapport.filter(
          (e) => formatDate(e.createdAt) === date
        );

        const income = inc.reduce((acc, cur) => acc + (cur?.amount || 0), 0);
        const total = inc.reduce((acc, cur) => acc + (cur?.total || 0), 0);
        const expense = exp.reduce((acc, cur) => acc + (cur?.amount || 0), 0);
        comparisons.value.push({
          number: 0,
          income,
          createdAt: new Date(inc[0]?.createdAt || exp[0]?.createdAt),
          expense,
          balance: income - expense,
          total,
          credit: total > income ? total - income : 0,
        });
      });
    }
  });
  function initData() {
    void load(
      COMPARATIVE_RAPPORT,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }
  function refresh() {
    Object.assign(filters.value, FILTER);
  }

  function print() {
    printRapport(` <table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="6">
            <h2 class="p-pt-3">${t("rapport.comparison")}</h2>
          </th>
        </tr>
        <tr>
          <th colspan="2" style="border-right: unset">
            ${t("rapport.period")}
          </th>
          <th colspan="2" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th colspan="2" style="border-left: unset">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>
        <tr>
          <th class="p-text-left">${t("date")}</th>
          <th>${t("rapport.payedIncome")}</th>
          <th>${t("rapport.unpayedIncome")}</th>
          <th>${t("rapport.totalIncome")}</th>
          <th>
            ${t("ticket.expenses")}
          </th>
          <th class="p-text-right">
            ${t("rapport.balance")}
          </th>
        </tr>
      </thead>
      <tbody>
        ${comparisons.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt as Date, "long")}
          </td>
          <td>
            ${formatNumber(data.income)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.credit)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.total)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>${formatNumber(data.expense)}</td>
          <td class="p-text-right">${formatNumber(data.balance)}</td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th class="p-text-left">
            ${t("rapport.total")}
          </th>
          <th>
            ${formatNumber(filteredTotals.income)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.credit)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.total)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.expense)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th class="p-text-right">
            ${formatNumber(filteredTotals.balance)}
            ${activeActivity.value.currencySymbol}
          </th>
        </tr>
      </tfoot>
    </table>`);
  }

  const filteredTotals = reactive({
    income: 0,
    expense: 0,
    balance: 0,
    credit: 0,
    total: 0,
  });

  function onFilter(event: any) {
    filteredTotals.income = 0;
    filteredTotals.expense = 0;
    filteredTotals.balance = 0;
    filteredTotals.credit = 0;
    filteredTotals.total = 0;
    (event.filteredValue as ComparativeRapport[]).forEach((row) => {
      filteredTotals.income += row.income;
      filteredTotals.expense += row.expense;
      filteredTotals.balance += row.balance;
      filteredTotals.credit += row.credit;
      filteredTotals.total += row.total;
    });
  }

  return {
    loading,
    comparisons,
    initData,
    refresh,
    filters,
    input,
    print,
    onFilter,
    filteredTotals,
    formatNumber,
  };
};
