
import { defineComponent } from "vue";
import { useComparativeRapport } from "@/graphql/rapport/comparative.rapport";
import RapportFilter from "@/components/rapport/RapportFilter.vue";
import { activeActivity } from "@/plugins/i18n";
import { CONSTANTS } from "@/graphql/utils/utils";

export default defineComponent({
  name: "ComparisonRapport",
  components: { RapportFilter },
  setup() {
    return {
      ...useComparativeRapport(),
      currencySymbol: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
    };
  },
  created() {
    this.initData();
  },
});
